<template>
    <div class="bestway">
        <div class="bestway-main">
            <div class="breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/xiangninghui'}">乡宁荟</el-breadcrumb-item>
                    <!-- <el-breadcrumb-item :to="{ path: '/bestway'}">徒步自驾</el-breadcrumb-item> -->
                    <el-breadcrumb-item >{{routeData.name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <!-- <div class="way-title clearfix">
            </div> -->
            <div class="way-detail">
                <el-row :gutter="20">
                    <el-col :span="18">
                        <div class="d-primary clearfix">
                            <div class="d-primary-pic fl" v-for="item in routeData.photoUrls" :key="item+'url'">
                                <el-image style="width:100%;height:100%" :src="item"></el-image>
                            </div>
                            <div class="d-primary-info fr">
                                <div class="d-primary-name" :title="routeData.name">{{routeData.name}}</div>
                                <div class="d-primary-h3">线路特色</div>
                                <div class="d-primary-intro">
                                    {{routeData.routesFeatures}}
                                </div>
                            </div>
                        </div>
                        <div class="d-step mt-20">
                            <div class="d-step-title">行程概况</div>
                            <div class="d-step-list">
                                <div class="d-step-item clearfix" v-for="(item, index) in pointsData" :key="index">
                                    <div class="fl d-step-market" v-if="index < moreNum">D{{item.dayIndex}}</div>
                                    <div class="fl d-step-info" v-if="index < moreNum">
                                        <div>{{item.touristPointsList.length>0 ? item.touristPointsList[0].name : ''}}
                                            {{item.touristPointsList.length>1 ? ' - '+item.touristPointsList[item.touristPointsList.length-1].name : ''}}
                                        </div>
                                        <div>
                                            <span v-for="(point, index) in item.touristPointsList" :key="point.id">
                                                {{index==0 ? '' : ' → '}}{{point.name}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-more" v-if="moreNum==3 && pointsData.length>3" @click="onMoreNum">
                                    更多<i class="el-icon-arrow-down"></i>
                                </div>
                            </div>
                        </div>
                        <div class="d-detail">
                            <div class="d-step-title">详情行程</div>
                            <div class="d-detail-list">
                                <div class="d-detail-item" v-for="(item, index) in pointsData" :key="index">
                                    <div class="d-detail-title">
                                        <div class="day">第{{item.dayIndex}}天</div>
                                        |
                                        <div>{{item.touristPointsList.length>0 ? item.touristPointsList[0].name : ''}}
                                            {{item.touristPointsList.length>1 ? ' - '+item.touristPointsList[item.touristPointsList.length-1].name : ''}}
                                        </div>
                                    </div>
                                    <div class="d-detail-intro">
                                              <!-- 今日从西宁出发，首站前往塔尔寺，塔尔寺三绝“堆绣、酥油花、壁画”更是深受喜爱。接着前往日月山
                                        与倒淌河，这两个景区可作为路途中参考景点选择性参观。下午游览青海湖边的二郎剑景区，若不愿意花钱进
                                        入二郎剑景区也可以给当地村民一部分钱，他们会带你去湖边，风景同样美。游览结束后驾车前往黑马河乡住
                                        宿。<br/>【贴士】今日行程中大多为山路，建议自备干粮。路上会有一些卖酸奶与鸡蛋的摊贩，经过乡镇的
                                        时候有一些私人饭店，青海湖景区内也有餐厅可以选择。 -->
                                    </div>
                                    <div class="d-day-list" v-for="point in item.touristPointsList" :key="point.id">
                                        <div class="d-day-h4">
                                            <span style="margin-right:20px">{{point.name}}</span>
                                            <el-popover
                                                placement="right"
                                                width="620"
                                                trigger="click">
                                                <div>
                                                    <!-- {{actInner}} -->
                                                    <near v-if="actInner === point.name" :lng="point.longitude" :lat="point.latitude" :name="point.name"></near>
                                                </div>
                                                <el-button type="primary" size="mini" @click="actInner = point.name" slot="reference">附近</el-button>
                                            </el-popover>
                                        </div>
                                        <div class="clearfix">
                                            <div class="d-day-content fl">
                                                <span class="c-6">游玩指南：</span>
                                                <span>
                                                    {{point.introduction}}
                                                </span>
                                                <!--
                                                <p>
                                                    <span class="c-6">【小贴士】</span>
                                                    <span>{{routeData.labels}}</span>
                                                </p>
                                                -->
                                            </div>
                                            <div class="d-day-pic fr"><!-- 这里要显示多图 -->
                                                <!-- <el-image style="width:100%;height:100%" :src="point.photoUrls[0]"></el-image> -->
                                                <el-carousel
                                                trigger="click"
                                                :autoplay="false"
                                                arrow="never"
                                                >
                                                <el-carousel-item
                                                    v-for="item in point.photoUrls"
                                                    :key="item + 'url'"
                                                >
                                                    <el-image
                                                    style="width: 100%; height: 100%"
                                                    :src="item"
                                                    ></el-image>
                                                </el-carousel-item>
                                                </el-carousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="hot mt-20">
                            <div class="hot-h2">经典线路推荐</div>
                            <div class="hot-item" v-for="item in touristRoutes" :key="item.id">
                                <router-link :to="`redirect?path=bestwaydetail&id=${item.id}`">
                                <el-image style="width:100%;height:100%" :src="item.photoUrls[0]"></el-image>
                                </router-link>
                                <div class="hot-title ellipsis">{{item.name}}</div>
                            </div>
                        </div>
                        
                    </el-col>
                </el-row>
            </div>
            <div style="height:200px"></div>
        </div>
        
    </div>
</template>

<script>
import {GetTouristRoutes, GetTouristPointsPageByDay, GetTouristRoutesPage} from "@/api/home";
import { regHtml } from "@/utils/reg";
import near from './near.vue'
export default {
    components:{
        near
    },
    data(){
        return{
            //旅游线路
            routeData:{name:'',photoUrls:''},
            //旅游线路点
            pointsData: [],
            //更多
            moreNum: 3,

            //经典线路推荐
            touristRoutes: [],
            actInner:'22'
        }
    },
    computed:{
        regHtml: () => regHtml,
    },
    mounted(){
        this.getRouteData();
        this.getPointsData();

        this.getRouristRoutes();
    },
    methods:{
        getRouteData(){
            GetTouristRoutes(this.$route.query.id).then(res=>{
                if (res.data?.success == true) {
                    const data = res.data.data;
                    data.photoUrls = data.photoUrls.split(','); //图片（1）
                    this.routeData = data;
                }
            })
        },
        getPointsData(){
            const data = { filter:{routesId: this.$route.query.id}, currentPage:1, pageSize:8 };
            GetTouristPointsPageByDay(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(day => {
                        day.touristPointsList.forEach(point => {
                            point.photoUrls = point.photoUrls.split(',');
                        })
                    })
                    this.pointsData = data;
                }
            })
        },
        onMoreNum(){
            this.moreNum = 10000;
        },
        getRouristRoutes(){
            const data = { filter:{routesTypes:'徒步自驾'}, currentPage:1, pageSize:8 };
            GetTouristRoutesPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.photoUrls = d.photoUrls.split(',');
                    })
                    this.touristRoutes = data;
                }
            })
        },
    },
}
</script>
<style scoped>
.hot-title{
    position: absolute;
    /* top: 80%; */
    bottom: 0;
    left: 0;
    font-size: 20px;
    color: #fff;
    /* transform: translateY(-50%); */
    width: 100%;
    line-height: 40px;
    text-align: center;
    background-image: linear-gradient(to top,rgba(0, 0, 0,.9) 0%,rgba(0, 0, 0,.7) 40%, transparent);

}
.hot-item{
    height: 185px;
    position: relative;
    margin-top: 20px;
}
.hot-h2{
    font-size: 24px;
}
.hot{
    padding: 20px;
    border: 1px solid #e5e5e5;
}
.c-6{
    color: #000;
    font-weight: 900;
    overflow: hidden;
}
.c-6 p{
    color: #0b58c2;
    font-weight: 900;
}
.d-day-list{
    padding: 20px;
}
.d-day-h4{
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
    color: #0b58c2;
}
.d-detail-intro{
    font-size: 16px;
    line-height: 28px;
    padding: 20px;
    text-indent: 30px;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;
}
.d-detail-title div{
    margin: 0 20px;
}
.d-detail-title{
    line-height: 40px;
    background: #0b58c2;
    color: #fff;
    font-size: 18px;
    display: flex;
}
.d-day-content{
    width: calc(100% - 440px);
    height: 300px;
    overflow-y: auto;
    font-size: 16px;
    line-height: 28px;
    color: #666;
}
.d-day-pic{
    width: 420px;
    height: 300px;
}
.d-more{
    font-size: 20px;
    color: #0b58c2;
    text-align: center;
    margin-top: 20px;
}
.d-step-info div:last-child{
    color:#0b58c2
}
.d-step-info{
    width: calc(100% - 47px);
    line-height: 33px;
    font-size: 16px;
    padding-left: 30px;
}
.d-step-market{
    width: 47px;
    height: 66px;
    font-size: 20px;
    line-height: 50px;
    color: #fff;
    text-align: center;
    background: url(../../../assets/img/d-market1.png) no-repeat;
}
.d-step-item{
    margin-top: 30px;
}
.d-step-list{
    padding-left: 20px;
}
.d-step-title{
    font-size: 20px;
    line-height: 40px;
    border-bottom: 1px solid #0e6ac1;
}
.d-primary{
    margin-top: 25px;
}
.d-primary-intro{
    font-size: 16px;
    line-height: 30px;
    text-indent: 28px;
    padding-top: 15px;
    height: 280px;
    overflow-y: auto;
}
.d-primary-h3{
    color: #fa054a;
    padding-left: 8px;
    line-height: 40px;
    font-size: 20px;
    border-bottom: 2px solid #e5e5e5;
}
.d-primary-name{
    line-height: 40px;
    font-size: 22px;
    font-weight: 800;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.d-primary-info{
    width: calc(100% - 580px);
    height: 420px;
    overflow: hidden;
    background: rgba(238, 238, 238,.4);
    padding: 10px;
}
.d-primary-pic{
    width: 580px;
    height: 420px;
}
.way-detail{
    font-family: '微软雅黑';
}
.way-title{
    margin-top: 10px;
    border-bottom: 2px solid #0e6ac1;
}
.bestway-main{
    width: 1200px;
    margin: 0px auto;
}
.bestway-main >>> .el-breadcrumb__inner.is-link{
    font-size: 16px;
    color: #0e6ac1;
}
.bestway-main >>> .el-breadcrumb__inner{
    color: #141414;
    font-size: 16px;
}
.bestway-main >>> .el-breadcrumb__separator{
    color: #141414;
}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>>