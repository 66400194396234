<template>
    <div class="near">
        <el-tabs type="card" @tab-click="tabChange">
            <el-tab-pane label="附近景点">
                <div class="jdian" v-if="ScenerySpotPageData.length>0">
                    <div class="jd-item" v-for="item in ScenerySpotPageData" :key="item.id">
                        <div class="jd_l" @click="$router.push(`/scenerySpotdetail?id=${item.id}`)">
                            <el-image style="width:100%;height:100%" :src="item.photoUrls[0]" fit="cover"></el-image>
                        </div>
                        <div class="jd_r">
                            <div class="row">
                                <div class="label">名称：</div>
                                <div class="value">{{item.name}}</div>
                            </div>
                            <div class="row">
                                <div class="label">星级：</div>
                                <div class="value">{{item.grade}}</div>
                            </div>
                            <div class="row">
                                <div class="label">价格：</div>
                                <div class="value">{{item.price}}元</div>
                            </div>
                            <div class="row">
                                <div class="label">预定电话：</div>
                                <div class="value">{{item.telephone}}</div>
                            </div>
                            <div class="row">
                                <div class="label ">简介：</div>
                                <div class="value row_2">{{item.introduction}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>
                    <el-empty description="暂无相关数据"></el-empty>
                </div>
            </el-tab-pane>
            <el-tab-pane label="附近住宿">
                <div class="jdian" v-if="SharedFarmPageData.length>0">
                    <div class="jd-item" v-for="item in SharedFarmPageData" :key="item.id">
                        <div class="jd_l" @click="$router.push(`/sharehousedetail?id=${item.id}`)">
                            <el-image style="width:100%;height:100%" :src="item.photoUrls[0]" fit="cover"></el-image>
                        </div>
                        <div class="jd_r">
                            <div class="row">
                                <div class="label">名称：</div>
                                <div class="value">{{item.name}}</div>
                            </div>
                            <div class="row">
                                <div class="label">类型：</div>
                                <div class="value">{{item.businessType}}</div>
                            </div>
                            <div class="row">
                                <div class="label">价格：</div>
                                <div class="value">{{item.pcConsumption}}元</div>
                            </div>
                            <div class="row">
                                <div class="label">预定电话：</div>
                                <div class="value">{{item.telephone}}</div>
                            </div>
                            <div class="row">
                                <div class="label">简介：</div>
                                <div class="value row_2">{{item.introduction}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>
                    <el-empty description="暂无相关数据"></el-empty>
                </div>
            </el-tab-pane>
            <el-tab-pane label="位置">
                <!-- <baidu-map
                    v-if="mapShow"
                    class="map"
                    :center="{ lng: lng, lat: lat }"
                    :scroll-wheel-zoom="true"
                    :zoom="14"
                >
                    <bm-marker
                        :position="{
                            lng: lng,
                            lat: lat,
                        }"
                        :dragging="true"
                    >
                    <bm-label
                        :content="name"
                        :labelStyle="{
                        color: 'skyblue',
                        fontSize: '16px',
                        border: '1px solid skyblue',
                        transform: 'translateX(-48%)',
                        }"
                        :offset="{ width: 0, height: 30 }"
                    />
                    </bm-marker>
                </baidu-map> -->
                <div style="width:100%;height:400px">
                    <arc-map :title="name" :center="[lng, lat]"></arc-map>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {
    GetScenerySpotPage,
    GetSharedFarmPage,
} from "@/api/home.js";
import { mapState } from "vuex";
export default {
    computed:{
        ...mapState({
            lonR:a=>a.lonR,
            latR:a=>a.latR,
        })
    },
    data(){
        return{
            item:"",
            SharedFarmPageData:[],
            ScenerySpotPageData:[],
            mapShow:false
        }
    },
    props:{
        lng:Number,
        lat:Number,
        name:String
    },
    methods:{
        tabChange(a){
            if(a.label == '位置'){
                this.mapShow = true
            }else{
                this.mapShow = false
            }
        },
        getSharedFarmPage() {
            const params = {
                currentPage: 1,
                pageSize: 3,
                filter: {
                businessType: "精品民宿",
                    longitude: this.lng,
                    longitudeR: this.lonR,
                    latitude: this.lat,
                    latitudeR: this.latR
                },
            }
            GetSharedFarmPage(params).then((res) => {
                if (res.data?.success) {
                const data = res.data.data.list;
                data.forEach((d) => {
                    d.photoUrls = d.photoUrls.split(",");
                });
                this.SharedFarmPageData = data;
                }
            });
        },
        getScenerySpotPage() {
            const params = {
                currentPage: 1,
                pageSize: 3,
                filter: {
                    longitude: this.lng,
                    longitudeR: this.lonR,
                    latitude: this.lat,
                    latitudeR: this.latR
                },
            }
            GetScenerySpotPage(params).then((res) => {
                if (res.data?.success) {
                const data = res.data.data.list;
                data.forEach((d) => {
                    d.photoUrls = d.photoUrls.split(",");
                });
                this.ScenerySpotPageData = data;
                }
            });
        },
    },
    mounted(){
        this.getScenerySpotPage();
        this.getSharedFarmPage();
    }
}
</script>

<style scoped>
.jdian{

}
.row_2{
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.map{
    height: 500px;
}
.jd-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.jd_l{
    width: 260px;
    height: 174px;
    cursor: pointer;
}
.jd_r{
    width: calc(100% - 274px);
    height: 174px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px ;
    line-height: 28px;
}
.jd_r .label{
    width: 80px;
}
.jd_r .row:nth-child(1) .value{ color: #333;}
.jd_r .row:nth-child(2) .value{ color: #367bff;}
.jd_r .row:nth-child(3) .value{ color: #f1632b;font-size: 20px;}
.jd_r .row:nth-child(4) .value{ color: #222;}
.label{
    width: 50px;
    color: #666;
}
.value{
    width: calc( 100% - 50px);
}
.near{
    /* width: 640px;
    height: 600px; */
}
</style>